
import {Ref, ref, watch} from 'vue';
import {addArticle, getArticleByIdBacked, getCategories, getTags, updateArticle, uploadImage} from '@/api/article';
import {ElMessage, ElMessageBox} from 'element-plus';
import {useRouter} from "vue-router";
import {addDraft, deleteDraft, getDraft, updateDraft} from "@/api/draft";

export default {
  setup() {
    const router = useRouter();
    const article = ref({
      id: null,
      articleId: null,
      categoryId: null,
      draftId: null,
      tagIds: [],
      title: "",
      summary: "",
      content: "",
      visible: 1,
      top: 0,
      createdAt: "",
    });
    const articleId = ref(router.currentRoute.value.params.articleId);
    let draftId = router.currentRoute.value.params.draftId;

    watch(() => router.currentRoute.value.params.draftId, () => {
      draftId = router.currentRoute.value.params.draftId;
    });

    watch(() => router.currentRoute.value.params, () => {
      articleId.value = router.currentRoute.value.params.articleId;
      // 如果是更新文章，获取文章信息
      if (articleId.value != null) {
        //拉取文章信息
        getArticleByIdBacked(articleId.value).then((data) => {
          let articleData = ref();
          articleData.value = data.data;
          let tagIds = [];
          articleData.value.tags.forEach(item => {
            tagIds.push(item.id);
          });
          article.value = articleData.value;
          article.value.tagIds = tagIds;
          draftId = articleData.value.draftId;
          loading.value = false;
        })
      } else if (router.currentRoute.value.params.draftId != null) {
        // 从草稿中恢复
        getDraft(router.currentRoute.value.params.draftId).then((data) => {
          let draftData = ref();
          draftData.value = data.data;
          article.value.title = draftData.value.title;
          article.value.summary = draftData.value.summary;
          article.value.content = draftData.value.content ? draftData.value.content : "";
          article.value.id = draftData.value.articleId;
          loading.value = false;
        })
      } else {
        article.value = {
          id: null,
          articleId: null,
          categoryId: null,
          draftId: null,
          tagIds: [],
          title: "",
          summary: "",
          content: "",
          visible: 1,
          top: 0,
          createdAt: "",
        };
      }
    }, {immediate: true});
    let categories: Ref = ref([]);
    let tags: Ref = ref([]);
    let dialogVisible = ref(false);
    let loading = ref(false);
    getCategories().then((data) => {
      categories.value = data.data;
    });

    getTags().then((data) => {
      tags.value = data.data;
    });

    const publishArticle = () => {
      if (article.value.title.trim() == "") {
        ElMessage({
          message: '文章标题不能为空!',
          type: 'error',
        });
        return;
      }
      if (article.value.content.trim() == "") {
        ElMessage({
          message: '文章内容不能为空!',
          type: 'error',
        });
        return;
      }
      loading.value = false;
      dialogVisible.value = true;
    };

    const onConfirmClick = () => {
      loading.value = true;
      article.value.draftId = draftId;
      if (article.value.id != null) {
        updateArticle(article.value).then((data: any) => {
          dialogVisible.value = false;
          loading.value = false;
          if (!data.errCode) {
            ElMessage({
              message: "文章更新成功!",
              type: 'success',
            });
            router.push('/articles/list');
          }
        });
      } else {
        addArticle(article.value).then((data: any) => {
          dialogVisible.value = false;
          if (!data.errCode) {
            ElMessage({
              message: "发布成功！",
              type: 'success',
            });
            article.value.articleId = data.data;
            updateDraft(article.value);
            router.push('/articles/list');
          } else {
            ElMessage.error(data.detail);
          }
        });
      }
    }

    let isDraftSaved = false;

    // 定时检查草稿是否保存
    setInterval(() => {
      if (!isDraftSaved) {
        saveDraft();
      }
    }, 5000);

    const draftChanged = () => {
      isDraftSaved = false;
    }

    // 保存草稿
    const saveDraft = () => {
      isDraftSaved = true;
      if (article.value.title != '' || article.value.content != '' || article.value.summary != '') {
        if (draftId == null) {
          // 这个id是因为数据库中存储的id没有辨识，需要修改
          article.value.articleId = article.value.id;
          addDraft(article.value).then((data: any) => {
            if (!data.errCode) {
              draftId = data.data;
              console.log("2333333", article.value.createdAt);
              console.log("创建草稿: ", draftId);
            } else {
              console.error("保存草稿失败！", data.detail);
            }
          });
        } else {
          article.value.draftId = draftId;
          updateDraft(article.value).then((data: any) => {
            if (!data.errCode) {
              console.log("更新草稿: ", draftId);
            } else {
              if (data.errCode == 'GENERAL_EXCEPTION') {
                draftId = null;
                return;
              }
              console.error("更新草稿失败！", data);
            }
          });
        }
      }
    }

    const handleUploadImage = (event, insertImage, files) => {
      // 上传图片到服务器
      uploadImage(files[0]).then((data: any) => {
        if (!data.errCode) {
          insertImage({
            url: data.data,
            desc: files[0].name,
          });
        } else {
          ElMessage.error(data.detail);
        }
      });
    }

    const openDraftBox = () => {
      router.push('/draft/list');
    }

    return {
      categories,
      tags,
      article,
      draftId,
      dialogVisible,
      loading,
      publishArticle,
      onConfirmClick,
      saveDraft,
      handleUploadImage,
      openDraftBox,
      articleId,
      draftChanged,
    }
  }
}
